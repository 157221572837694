import { Grid, useMediaQuery } from "@mui/material";
import { Region, Location } from "../../../types";
import { TypedText } from "../../atoms";
import { LocationInformation } from "./LocationInformation";
import { VisualFeature } from "./VisualFeature";

interface RegionInformationProps {
  region: Partial<Region>;
  locations: Location[];
}

export const RegionInformation = ({
  region,
  locations,
}: RegionInformationProps) => {
  const isMobile = useMediaQuery("(min-width: 1300px)");
  const xs = isMobile ? 6 : 12;

  return (
    <Grid
      container
      rowSpacing={4}
      flexDirection="column"
      flexWrap="nowrap"
      style={{ marginBottom: "3vh" }}
    >
      <Grid item xs={12}>
        <TypedText fontSize="2.8vh" fontWeight="bold">
          The {region.name || "unnamed"} region
        </TypedText>

        <TypedText fontSize="1.4vh" fontStyle="italic">
          {region.description}
        </TypedText>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          rowSpacing={8}
          flexDirection="row"
          flexWrap="wrap"
        >
          {locations.map((loc, idx) => (
            <Grid item xs={xs} style={{ justifyContent: "stretch" }}>
              <LocationInformation
                location={loc}
                alignment={idx % 2 === 0 ? "left" : "right"}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
