import { useContext } from "react";
import { ChatContext, ChatProvider, useSession } from "../../../context";
import { ChatWindow } from "../../organisms/chat-window";

export const Chat = () => {
  const [sessionId] = useSession();
  const { loadMore } = useContext(ChatContext);

  return (
    <ChatProvider sessionId={sessionId}>
      <ChatWindow
        onLoadMore={() => {
          loadMore();
        }}
      />
    </ChatProvider>
  );
};
