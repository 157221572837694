import { Config } from "../config";
import { WorldData } from "../types";

class WorldApi {
  getWorld(id: string): Promise<WorldData> {
    return fetch(`${Config.ApiBaseUrl}/worlds/${id}`, {
      headers: localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        : undefined,
    })
      .then((response) => response.json())
      .then((json) => json as WorldData);
  }
}

export default new WorldApi();
