import { Skeleton, Chip, Grid, Box, CircularProgress } from "@mui/material";
import { Config } from "../../../config";
import { TypedText } from "../../atoms";

interface VisualFeatureProps {
  imageAssetId?: string | null;
  bottom?: React.ReactNode;
  children: React.ReactNode;
  alignment: "left" | "right";
}

export const VisualFeature = ({
  imageAssetId,
  bottom,
  children,
  alignment,
}: VisualFeatureProps) => {
  return (
    <Grid
      container
      flexDirection={alignment === "left" ? "row" : "row-reverse"}
      flexWrap="nowrap"
      spacing={4}
      style={{ height: "100%" }}
    >
      <Grid item xs={4}>
        <Grid container flexDirection="column" flexWrap="nowrap" spacing={1}>
          <Grid item xs={12}>
            {imageAssetId && (
              <img
                src={`${Config.ApiBaseUrl}/assets/${imageAssetId}`}
                style={{
                  borderRadius: "0.5rem",
                  aspectRatio: "1/1",
                  width: "100%",
                  objectFit: "cover",
                  boxShadow: "0px 3px 10px 3px rgba(115,115,115,0.25)",
                }}
              />
            )}
            {!imageAssetId && (
              <Box position="relative">
                <CircularProgress
                  variant="indeterminate"
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    marginLeft: "-5%",
                    marginTop: "-5%",
                    fontSize: "5%",
                  }}
                />

                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                  style={{
                    borderRadius: "0.5rem",
                    aspectRatio: "1/1",
                    width: "100%",
                    objectFit: "cover",
                    boxShadow: "0px 3px 10px 3px rgba(115,115,115,0.25)",
                  }}
                />
              </Box>
            )}
          </Grid>

          {bottom && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {bottom}
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={8} justifyContent="stretch">
        <Grid
          container
          spacing={2}
          flexDirection="column"
          flexWrap="nowrap"
          alignItems={alignment === "left" ? "flex-start" : "flex-end"}
          textAlign={alignment === "left" ? "left" : "right"}
        >
          <Grid item xs={12} justifyContent="stretch">
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
