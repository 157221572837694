import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useWorld } from "../../../context/app/hooks/useWorld";
import { RegionInformation } from "../../molecules/world-overview";
import { Region } from "../../../types";

export const Locations = () => {
  const world = useWorld();

  if (!world || !world.regions) return <></>;

  const getLocations = (region: Region) => {
    return (
      world.locations?.filter(
        (location) => location.regionName === region.name
      ) || []
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" fontSize="3.6vh">
          Places in the world
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box padding="2em">
          <Grid container flexDirection="row" columnSpacing={12}>
            {world.regions.map((region, idx) => (
              <Grid item xs={12}>
                <RegionInformation
                  key={`region-${idx}`}
                  region={region}
                  locations={getLocations(region)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
