import { Config } from "../config";
import { Asset } from "../types";

class AssetApi {
  create(
    sessionId: string,
    file: File,
    onProgress?: (percentage: number) => void
  ): Promise<Asset> {
    const formData = new FormData();

    formData.append("file", file, file.name);

    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();

      request.open("POST", `${Config.ApiBaseUrl}/assets/${sessionId}`);

      if (localStorage.getItem("token")) {
        request.setRequestHeader(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
      }

      request.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentage = Math.round((event.loaded * 100) / event.total);

          if (onProgress) {
            onProgress(percentage);
          }
        }
      });

      request.addEventListener("load", () => {
        if (request.status === 200) {
          const data = JSON.parse(request.responseText);

          resolve(data);
        } else {
          reject(new Error("Error uploading asset"));
        }
      });

      request.send(formData);
    });
  }
}

export default new AssetApi();
