import { createContext, useEffect, useState } from "react";
import { WorldData } from "../../types";
import WorldApi from "../../api/WorldApi";
import UserApi from "../../api/UserApi";

type Context = {
  isAuthenticated: boolean;
  sessionId: string | null;
  world: WorldData | null;
  setSessionId: (sessionId: string | null) => void;
  setWorld: (world: WorldData | null) => void;
  createAccount: (
    username: string,
    email: string,
    password: string
  ) => Promise<void>;
  login: (identity: string, password: string) => Promise<void>;
  logout: () => void;
};

const defaultValue: Context = {
  isAuthenticated: false,
  sessionId: null,
  setSessionId: () => {},
  setWorld: () => {},
  world: null,
  createAccount: async () => {},
  login: async () => {},
  logout: () => {},
};

export const AppContext = createContext(defaultValue);

interface ProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [world, setWorld] = useState<WorldData | null>(null);

  useEffect(() => {
    if (localStorage.getItem("sessionId")) {
      setSessionId(localStorage.getItem("sessionId"));
    }

    if (localStorage.getItem("worldId")) {
      WorldApi.getWorld(localStorage.getItem("worldId") as string).then(
        (world) => {
          setWorld(world);
        }
      );
    }

    handleAccessCheck();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    handleAccessCheck();
    const accessCheckInterval = setInterval(handleAccessCheck, 1000 * 60 * 5);

    return () => {
      clearInterval(accessCheckInterval);
    };
  }, [isAuthenticated]);

  const handleAccessCheck = async () => {
    try {
      await UserApi.getProfile();

      setIsAuthenticated(true);
    } catch {
      if (localStorage.getItem("refreshToken")) {
        try {
          const newTokens = await UserApi.refreshAccessToken(
            localStorage.getItem("refreshToken") as string
          );
          localStorage.setItem("token", newTokens.accessToken);
          localStorage.setItem("refreshToken", newTokens.refreshToken);
          await UserApi.getProfile();

          setIsAuthenticated(true);
        } catch {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("token");
          setIsAuthenticated(false);
        }
      } else {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      }
    }
  };

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        sessionId,
        world,
        setSessionId: (sessionId: string | null) => {
          if (sessionId) {
            localStorage.setItem("sessionId", sessionId);
          } else {
            localStorage.removeItem("sessionId");
          }
          setSessionId(sessionId);
        },
        setWorld,
        createAccount: async (
          username: string,
          email: string,
          password: string
        ) => {
          if (isAuthenticated) return;

          try {
            const tokens = await UserApi.register(username, email, password);

            localStorage.setItem("token", tokens.accessToken);
            localStorage.setItem("refreshToken", tokens.refreshToken);

            setIsAuthenticated(true);
          } catch (err) {
            console.error(err);
          }
        },
        login: async (identity: string, password: string) => {
          if (isAuthenticated) return;

          try {
            const tokens = await UserApi.login(identity, password);

            localStorage.setItem("token", tokens.accessToken);
            localStorage.setItem("refreshToken", tokens.refreshToken);

            setIsAuthenticated(true);
          } catch (error) {
            console.error(error);
          }
        },
        logout: () => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("sessionId");
          localStorage.removeItem("worldId");
          setIsAuthenticated(false);
          setSessionId(null);
          setWorld(null);
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
