// TODO: This should be used to access properties of the JWT token

import { useContext } from "react";
import { AppContext } from "../AppContext";

interface Identity {
  isAuthenticated: boolean;
  login: (identity: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    username: string,
    emailAddress: string,
    password: string
  ) => Promise<void>;
}

export const useIdentity = (): Identity => {
  const { isAuthenticated, login, createAccount, logout } =
    useContext(AppContext);

  const handleLogin = async (
    identity: string,
    password: string
  ): Promise<void> => {
    await login(identity, password);
  };

  const handleLogout = (): void => {
    logout();
  };

  const handleRegister = async (
    username: string,
    emailAddress: string,
    password: string
  ): Promise<void> => {
    await createAccount(username, emailAddress, password);
  };

  return {
    isAuthenticated,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
  };
};
