import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useWorld } from "../../../context/app/hooks/useWorld";
import { RouteInformation } from "../../molecules/world-overview";

export const Routes = () => {
  const isMobile = useMediaQuery("(min-width: 1300px)");
  const xs = isMobile ? 6 : 12;
  const world = useWorld();

  if (!world || !world.routes || !world.routes.length) return <></>;

  return (
    <Box padding="2em">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight="bold" fontSize="3.6vh">
            Routes between places
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box padding="1em">
            <Grid
              container
              flexDirection="row"
              flexWrap="wrap"
              rowSpacing={4}
              columnSpacing={4}
            >
              {world.routes.map((route, idx) => (
                <Grid item xs={xs} justifyContent="stretch">
                  <RouteInformation key={`route-${idx}`} route={route} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
