import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Message, Progress, WorldData } from "../../types";
import ChatApi from "../../api/ChatApi";
import { AppContext, useSession } from "../app";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Config } from "../../config";

type Context = {
  messages: Message[];
  statusMessage: Message | null;
  isAwaitingResponse: boolean;
  sendMessage: (message: string, attachmentIds: string[]) => void;
  loadMore: () => void;
};

const defaultValue: Context = {
  messages: [],
  statusMessage: null,
  isAwaitingResponse: false,
  sendMessage: () => {},
  loadMore: () => {},
};

export const ChatContext = createContext(defaultValue);

interface ProviderProps {
  sessionId: string | null;
  children: React.ReactNode;
}

export const ChatProvider: React.FC<ProviderProps> = ({
  sessionId,
  children,
}) => {
  const { setWorld } = useContext(AppContext);
  const hasInitializedRef = useRef(false);
  const connectionRef = useRef<HubConnection | null>(null);
  const [history, setHistory] = useState<Message[]>([]);
  const [_, setSessionId] = useSession();
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [statusMessage, setStatusMessage] = useState<Message | null>(null);

  const handleLoadMessages = (progressive?: boolean) => {
    if (!sessionId) return;

    console.log(`load messages for session ${sessionId}`);
    if (!sessionId) return;

    const lastMessage = history[0];

    console.log(`load, progressive: ${progressive}`);

    ChatApi.getMessages(sessionId, progressive ? lastMessage?.id : undefined)
      .then((messages) => {
        if (progressive) {
          setHistory((prev) => [...messages, ...prev]);
        } else {
          setHistory(messages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    connectionRef.current = new HubConnectionBuilder()
      .withUrl(`${Config.WorldBuilderHubUrl}?session=${sessionId}`, {
        headers: {
          Authorization: localStorage.getItem("token")
            ? `Bearer ${localStorage.getItem("token")}`
            : "",
        },
      })
      .withAutomaticReconnect()
      .build();

    connectionRef.current
      .start()
      .then(() => {
        console.log("connected to hub");
      })
      .catch((err) => console.error(err.toString()));

    connectionRef.current.on(
      "HandleWorldData",
      (worldId: string, worldData: WorldData) => {
        console.log("HandleWorldData", worldData);
        setWorld(worldData);
        localStorage.setItem("worldId", worldId);
      }
    );

    connectionRef.current.on("HandleProgress", (progress: Progress) => {
      setIsAwaitingResponse(false);

      setStatusMessage({
        id: new Date().getTime().toString(),
        sender: "assistant",
        text: progress.status,
        attachments: [],
        createdAt: new Date().toLocaleDateString(),
      });
    });

    connectionRef.current.on("HandleCompletion", () => {
      setStatusMessage(null);
    });

    connectionRef.current.on("HandleNewSession", (sessionId: string) => {
      console.log("HandleNewSession", sessionId);
      setSessionId(sessionId);
    });

    connectionRef.current.on("HandleNewMessage", (message: Message) => {
      setIsAwaitingResponse(false);
      console.log("HandleNewMessage", message);
      setHistory((prev) => [...prev, message]);
    });

    connectionRef.current.onclose(() => {});

    handleLoadMessages();

    return () => {
      connectionRef.current?.stop();
      connectionRef.current = null;
    };
  }, [sessionId]);

  const handleSendMessage = useCallback(
    (message: string, attachmentIds: string[]) => {
      setIsAwaitingResponse(true);

      setHistory((prev) => [
        ...prev,
        {
          id: new Date().getTime().toString(),
          sender: "user",
          text: message,
          attachments: [],
          createdAt: new Date().toLocaleDateString(),
        },
      ]);

      setStatusMessage(null);

      connectionRef.current
        ?.invoke("HandleInputAsync", message, attachmentIds)
        .catch(() => {
          if (statusMessage) {
            setStatusMessage(null);
            setIsAwaitingResponse(false);

            setHistory((prev) => [
              ...prev,
              {
                id: new Date().getTime().toString(),
                sender: "assistant",
                text: "Sorry, something went wrong when I tried to process your message. Could you try again?",
                attachments: [],
                createdAt: new Date().toLocaleDateString(),
              },
            ]);
          }
        });
    },
    [sessionId, history]
  );

  return (
    <ChatContext.Provider
      value={{
        messages: history,
        statusMessage,
        isAwaitingResponse,
        sendMessage: handleSendMessage,
        loadMore: () => {
          handleLoadMessages(true);
        },
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
