import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIdentity } from "../../../context";

interface LoginProps {}

export const Login = ({}: LoginProps) => {
  const { login, register } = useIdentity();

  const [mode, setMode] = useState<"login" | "register">("login");
  const [identity, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (mode === "login") {
      login(identity, password);
    } else {
      register(identity, email, password);
    }
  };

  const handleModeSwitch = () => {
    setMode(mode === "login" ? "register" : "login");
    setEmail("");
    setPassword("");
  };

  return (
    <Container style={{ display: "flex", height: "100%" }}>
      <Box
        display="flex"
        flexDirection="column"
        justifySelf="center"
        alignSelf="center"
        width="30em"
        minHeight="25vh"
        margin="auto"
        border="1px solid #ccc"
        borderRadius="0.5rem"
        padding="1em"
        boxShadow="0px 8px 10px 4px rgba(220, 220, 220, 0.15)"
      >
        <Box padding="0.5em">
          <Typography variant="h4">World Forge Alpha Testing</Typography>
        </Box>

        <Divider />

        <Box padding="1em">
          <FormControl fullWidth>
            <TextField
              label={mode === "login" ? "Username or Email" : "Username"}
              variant="standard"
              size="small"
              fullWidth
              hiddenLabel
              value={identity}
              onChange={(e) => setUsername(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
              InputLabelProps={{
                size: "normal",
              }}
            />
          </FormControl>
        </Box>

        {mode === "register" && (
          <Box padding="1em">
            <FormControl fullWidth>
              <TextField
                label="Email"
                variant="standard"
                size="small"
                fullWidth
                hiddenLabel
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
                InputLabelProps={{
                  size: "normal",
                }}
              />
            </FormControl>
          </Box>
        )}

        <Box padding="1em">
          <FormControl fullWidth>
            <TextField
              label="Password"
              variant="standard"
              size="small"
              type="password"
              fullWidth
              hiddenLabel
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
              InputLabelProps={{
                size: "normal",
              }}
            />
          </FormControl>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifySelf="flex-end"
          padding="1em"
        >
          <Button variant="contained" onClick={handleSubmit}>
            {mode === "login" ? "Login" : "Create Account"}
          </Button>

          <Button variant="text" onClick={handleModeSwitch}>
            {mode === "login" ? "Need an account" : " Already have an account?"}
          </Button>

          <Box height="1em" />

          <Typography variant="body2" color="error">
            Invalid username or password
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
