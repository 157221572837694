import { Config } from "../config";
import { Message, Reply } from "../types";

class ChatApi {
  async getResponse(
    sessionId: string | null,
    message: string,
    files: File[]
  ): Promise<Reply> {
    const formData = new FormData();

    formData.append("input", message);

    files.forEach((file, index) => {
      formData.append(`file`, file, file.name);
    });

    const response = await fetch(
      `${Config.ApiBaseUrl}/worlds/build${sessionId ? `/${sessionId}` : ""}`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token")
            ? `Bearer ${localStorage.getItem("token")}`
            : "",
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Error fetching response from server");
    }

    const data = await response.json();

    return data;
  }

  async getMessages(sessionId: string, before?: string): Promise<Message[]> {
    const response = await fetch(
      `${Config.ApiBaseUrl}/worlds/build/${sessionId}/messages${
        before ? `?before=${before}` : ""
      }`,
      {
        headers: {
          Authorization: localStorage.getItem("token")
            ? `Bearer ${localStorage.getItem("token")}`
            : "",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error fetching messages from server");
    }

    const data = await response.json();

    return data;
  }
}

export default new ChatApi();
