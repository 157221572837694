import { useEffect, useRef, useState } from "react";

interface TypedTextProps {
  duration?: number;
  children: string | string[] | undefined;
  style?: React.CSSProperties;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
  fontStyle?: "normal" | "italic" | "oblique" | "initial" | "inherit";
}

export const TypedText = ({
  fontSize,
  fontWeight,
  fontStyle,
  textAlign,
  duration,
  children,
  style,
}: TypedTextProps) => {
  const value =
    children === "undefined"
      ? ""
      : (Array.isArray(children) ? children.join("") : children) || "";
  const lastTextRef = useRef(value);
  const [typedValue, setTypedValue] = useState("");

  useEffect(() => {
    if (value.trim().length === 0) return;

    if (lastTextRef.current === children) {
      setTypedValue(parseValue(children));
      return;
    }

    lastTextRef.current = value;

    const interval = setInterval(() => {
      setTypedValue((prev) => {
        if (prev.length === value.length) {
          clearInterval(interval);
          return prev;
        }

        return parseValue(value.slice(0, prev.length + 1));
      });
    }, (duration || 1000) / value.length);

    return () => {
      clearInterval(interval);
    };
  }, [children]);

  function parseValue(value: string) {
    value = value.replace(/\n/g, "<br />");

    let result = value;

    // regex replace everything inside * with <b>
    const strongRegex = /\*(.*?)\*/g;
    const strongMatches = value.match(strongRegex);

    if (strongMatches) {
      strongMatches.forEach((match) => {
        result = result.replace(match, `<b>${match.replace(/\*/g, "")}</b>`);
      });
    }

    return result;
  }

  return (
    <p
      dangerouslySetInnerHTML={{ __html: typedValue }}
      style={{
        ...(style || {}),
        margin: 0,
        fontSize,
        fontWeight,
        fontStyle,
        textAlign,
      }}
    />
  );
};
