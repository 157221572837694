import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { WorldOverview } from "../world-overview";
import { Chat } from "../../containers";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { BookOpen, MessageCircle } from "react-feather";
import { useWorld } from "../../../context/app/hooks/useWorld";
import { WorldData } from "../../../types";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-height: 100%;
`;

const WorldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  overflow-y: scroll;
  padding: 3em;
  flex-grow: 1;
  width: 90%;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  @media (max-width: 1300px) {
    padding: 0;
  }

  @media (min-width: 1301px) {
    width: 60%;
  }

  @media (min-width: 1720px) {
    width: 60%;
  }

  @media (min-width: 2186px) {
    width: 60%;
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  height: calc(100vh - 5em);
  margin-top: 3em;
  width: 10%;
  margin-right: 2em;
  border-radius: 1.1rem;
  transition: all 0.3s ease-in-out;

  &.no-world {
    position: fixed;
    left: 50%;
    top: 45vh;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 90vh;
  }

  @media (max-width: 1300px) {
    width: 100%;
    margin: 0;
    height: 100dvh;
    box-shadow: none;
  }

  @media (min-width: 1301px) {
    width: 40%;
    padding: 0;
  }

  @media (min-width: 1720px) {
    width: 40%;
  }

  @media (min-width: 2186px) {
    width: 40%;
  }
`;

const MobileSwitcher = styled.div`
  position: absolute;
  z-index: 1000;
  right: 1em;
  top: 1.5em;

  @media (min-width: 1301px) {
    display: none;
  }
`;

export const WorldBuilder = () => {
  const world = useWorld();
  const isMobile = useMediaQuery("(max-width: 1300px)");
  const [mode, setMode] = useState<"world" | "chat">("world");

  return (
    <Wrapper>
      {isMobile && (
        <MobileSwitcher>
          <IconButton
            onClick={() => setMode(mode === "world" ? "chat" : "world")}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
          >
            {mode === "world" && <MessageCircle color="white" />}
            {mode === "chat" && <BookOpen color="white" />}
          </IconButton>
        </MobileSwitcher>
      )}

      <WorldWrapper
        style={{
          display: !isMobile || mode === "world" ? "flex" : "none",
          opacity: world ? 1 : 0,
          position: world ? undefined : "fixed",
          width: world ? undefined : 0,
        }}
      >
        <WorldOverview />
      </WorldWrapper>

      <ChatWrapper
        style={{ display: !isMobile || mode === "chat" ? "flex" : "none" }}
        className={world ? undefined : "no-world"}
      >
        <Chat />
      </ChatWrapper>
    </Wrapper>
  );
};
