import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useWorld } from "../../../context/app/hooks/useWorld";
import { Config } from "../../../config";
import styled from "styled-components";
import { TypedText } from "../../atoms";

const WorldBannerImage = styled.img`
  transform: translateX(-1em);
  width: calc(100% + 2.02em);
  height: auto;
  object-fit: fill;
  aspect-ratio: 16 / 9;
  box-shadow: 0px 3px 15px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
`;

export const WorldInformation = () => {
  const world = useWorld();

  const worldDescription = world?.description;

  if (!worldDescription) return <></>;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ marginBottom: "0.5em" }}>
        <TypedText fontSize="3.8vh" fontWeight="900">
          The world of {world?.description?.name || ""}
        </TypedText>

        {worldDescription.tagline && (
          <TypedText fontSize="1.4vh" fontWeight="600">
            {worldDescription?.tagline}
          </TypedText>
        )}
      </Grid>

      <Grid item xs={12}>
        {worldDescription.imageAssetId && (
          <WorldBannerImage
            src={`${Config.ApiBaseUrl}/assets/${worldDescription.imageAssetId}`}
          />
        )}

        {!worldDescription.imageAssetId && (
          <Box position="relative">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height="auto"
              style={{
                aspectRatio: "16/9",
                borderRadius: "0.5rem",
                boxShadow: "0px 3px 15px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
            <LinearProgress variant="indeterminate" />
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Box paddingX="1em" paddingY="0.5em" paddingBottom="2em">
          <Typography variant="h6" fontWeight="bold" fontSize="2.1vh">
            About this world
          </Typography>
          <TypedText fontSize="1.4vh">{worldDescription.description}</TypedText>

          {worldDescription.environmentalHazards &&
            worldDescription.environmentalHazards.length > 0 && (
              <>
                <Typography variant="h6" fontWeight="bold" fontSize="2.1vh">
                  Environmental Hazards
                </Typography>

                <Grid container flexDirection="row" flexWrap="wrap">
                  {worldDescription.environmentalHazards.map((hazard) => (
                    <Grid item xs={6}>
                      <TypedText fontSize="1.6vh" fontWeight="bold">
                        {hazard.name}
                      </TypedText>

                      {hazard.regionNames && hazard.regionNames.length > 0 && (
                        <TypedText fontSize="1vh" fontStyle="italic">
                          Common in {hazard.regionNames.join(", ")}
                        </TypedText>
                      )}

                      {(!hazard.regionNames ||
                        hazard.regionNames.length === 0) && (
                        <TypedText fontSize="1vh" fontStyle="italic">
                          Common in all regions
                        </TypedText>
                      )}

                      <TypedText fontSize="1.4vh">
                        {hazard.description}
                      </TypedText>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
        </Box>
      </Grid>
    </Grid>
  );
};
