import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  Skeleton,
} from "@mui/material";
import { Race } from "../../../types";
import { TypedText } from "../../atoms";
import { Config } from "../../../config";
import { VisualFeature } from "./VisualFeature";
import { Frame } from "../../../styles";

interface RaceInformationProps {
  alignment: "left" | "right";
  race: Partial<Race>;
}

export const RaceInformation = ({
  alignment,
  race: { name, description, imageAssetId, lore, traits },
}: RaceInformationProps) => {
  return (
    <VisualFeature
      imageAssetId={imageAssetId}
      alignment={alignment}
      bottom={
        <Frame>
          <TypedText fontSize="0.9vh" textAlign={alignment}>
            {`${traits?.map((x) => `- ${x}`).join("\n")}`}
          </TypedText>
        </Frame>
      }
    >
      <Frame>
        <TypedText fontSize="2.8vh" fontWeight="bold">
          {name}
        </TypedText>
        <TypedText fontSize="1.3vh">
          {`*Backstory*\n${lore}\n\n*Description*\n${description}`}
        </TypedText>
      </Frame>
    </VisualFeature>
  );
};
