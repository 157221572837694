import { Config } from "../config";
import { UserProfile, UserTokens } from "../types";

class UserApi {
  async getProfile(): Promise<UserProfile> {
    const response = await fetch(`${Config.ApiBaseUrl}/user/me`, {
      headers: localStorage.getItem("token")
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        : undefined,
    });

    if (!response.ok) {
      throw new Error("Error fetching user profile from server");
    }

    const data = await response.json();

    return data;
  }

  async register(
    username: string,
    emailAddress: string,
    password: string
  ): Promise<UserTokens> {
    const formData = new FormData();

    formData.append("username", username);
    formData.append("email", emailAddress);
    formData.append("password", password);

    const response = await fetch(`${Config.ApiBaseUrl}/user/register`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Error registering user");
    }

    const data = await response.json();

    return data;
  }

  async login(identity: string, password: string): Promise<UserTokens> {
    const formData = new FormData();

    formData.append("identity", identity);
    formData.append("password", password);

    const response = await fetch(`${Config.ApiBaseUrl}/user/login`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Error logging in user");
    }

    const data = await response.json();

    return data;
  }

  async refreshAccessToken(refreshToken: string): Promise<UserTokens> {
    const formData = new FormData();

    formData.append("refreshToken", refreshToken);

    const response = await fetch(`${Config.ApiBaseUrl}/user/token/refresh`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Error refreshing user access token");
    }

    const data = await response.json();

    return data;
  }
}

export default new UserApi();
