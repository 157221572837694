import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useWorld } from "../../../context/app/hooks/useWorld";
import { CreatureInformation } from "../../molecules/world-overview";

export const Creatures = () => {
  const world = useWorld();
  const isMobile = useMediaQuery("(min-width: 1300px)");
  const xs = isMobile ? 6 : 12;

  if (!world || !world.creatures) return <></>;

  return (
    <Grid container flexDirection="column" flexWrap="nowrap">
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold" fontSize="3.6vh">
          Creatures & Monsters
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          flexDirection="row"
          rowSpacing={8}
          columnSpacing={4}
          flexWrap="wrap"
        >
          {world.creatures.map((creature, idx) => (
            <Grid item xs={xs} justifyContent="stretch">
              <CreatureInformation
                key={`creature-${idx}`}
                creature={creature}
                alignment={idx % 2 === 0 ? "left" : "right"}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
