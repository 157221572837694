import { Divider, Grid, Typography } from "@mui/material";
import { Character } from "../../../types";
import { TypedText } from "../../atoms";
import { VisualFeature } from "./VisualFeature";
import styled from "styled-components";
import { Frame } from "../../../styles";

interface CharacterInformationProps {
  character: Partial<Character>;
  alignment: "left" | "right";
}

export const CharacterInformation = ({
  character,
  alignment,
}: CharacterInformationProps) => {
  const textAlign = alignment === "left" ? "right" : "left";

  const Stat = ({ name, value }: { name: string; value?: number }) => {
    if (!value) return null;

    return (
      <Grid item>
        <Typography variant="body1" fontSize="1vh">
          <b>{name}:</b> {value}
        </Typography>
      </Grid>
    );
  };

  return (
    <VisualFeature
      imageAssetId={character.imageAssetId}
      alignment={alignment}
      bottom={
        <Frame>
          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize="1.4vh"
            textAlign={alignment}
          >
            Stats
          </Typography>

          <Grid
            container
            flexDirection={alignment === "left" ? "row" : "row-reverse"}
            flexWrap="wrap"
            columnSpacing={2}
          >
            <Stat
              name="Constitution"
              value={character.characteristics?.constitution}
            />
            <Stat
              name="Dexterity"
              value={character.characteristics?.dexterity}
            />
            <Stat
              name="Intelligence"
              value={character.characteristics?.intelligence}
            />
            <Stat name="Strength" value={character.characteristics?.strength} />
            <Stat name="Wisdom" value={character.characteristics?.wisdom} />
          </Grid>

          <TypedText fontSize="1vh" textAlign={alignment}>
            {`\n\n*Skills*\n${character.skills?.map((x) => x.name).join(", ")}`}
          </TypedText>
        </Frame>
      }
    >
      <Frame>
        <TypedText fontSize="2.4vh" fontWeight="900" textAlign={alignment}>
          {character.name}
        </TypedText>
        <TypedText
          fontSize="1.4vh"
          textAlign={alignment}
        >{`Level ${character.level} ${character.raceName} ${character.class}`}</TypedText>

        <Divider />

        <TypedText textAlign={alignment} fontSize="1.4vh">{`*Backstory*\n${
          character.history?.story || "None"
        }\n\n*Key Events*\n${
          character.history?.keyEvents?.map((x, idx) => `- ${x}`).join("\n") ||
          "None"
        }\n\n*Description*\n${character.description}`}</TypedText>
      </Frame>
    </VisualFeature>
  );
};
