import styled from "styled-components";

export const Frame = styled.div`
  background-color: #fcfcfc;
  box-shadow: 0 0.4em 0.5em rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(204, 204, 204, 0.8);
  padding: 1em;
  height: 100%;
  border-radius: 0.5em;
`;
