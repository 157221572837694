import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Skeleton,
} from "@mui/material";
import { Creature } from "../../../types";
import { TypedText } from "../../atoms";
import { Config } from "../../../config";
import { VisualFeature } from "./VisualFeature";
import { Frame } from "../../../styles";

interface CreatureInformationProps {
  creature: Partial<Creature>;
  alignment: "left" | "right";
}

export const CreatureInformation = ({
  creature,
  alignment,
}: CreatureInformationProps) => {
  const textAlign = alignment === "left" ? "right" : "left";

  const hostilityStr = creature.hostility
    ? ` ${
        creature.hostility.toString().substring(0, 1).toUpperCase() +
        creature.hostility.toString().substring(1)
      }`
    : `Unknown Hostility`;

  return (
    <VisualFeature imageAssetId={creature.imageAssetId} alignment={alignment}>
      <Frame>
        <TypedText fontSize="2.4vh" fontWeight="900">
          {creature.name}
        </TypedText>
        <TypedText fontSize="1.4vh">{hostilityStr}</TypedText>

        <Divider />

        <TypedText fontSize="1.4vh">{`*Description*\n${creature.description}`}</TypedText>
      </Frame>
    </VisualFeature>
  );
};
