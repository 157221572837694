import {
  Avatar,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { ChatInput, ChatMessage, Narration } from "../../molecules";
import { useChatMessages } from "../../../context";
import { useEffect, useMemo, useRef } from "react";

const ChatWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1.1rem;
  border: 1px solid #ccc;

  @media (max-width: 1300px) {
    border-radius: 0;
  }
`;

const ChatHeader = styled.div`
  display: flex;
  background-color: #f1f1f1;
  padding: 1em;
  height: 100%;
  border-bottom: 1px solid #ccc;
  flex: 0 0 100px;

  & .chat-window__footer {
    flex-basis: 100px;
  }
`;

const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: scroll;
  padding: 1em;
  flex: 1 1 auto;
`;

const ChatFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  height: 100%;
  flex: 0 0 100px;
`;

const MessageWrapper = styled.div`
  margin-bottom: 1em;
`;

interface ChatWindowProps {
  onLoadMore: () => void;
}

export const ChatWindow = ({ onLoadMore }: ChatWindowProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [messages, sendMessage, isWaiting, progress] = useChatMessages();

  useEffect(() => {
    // scroll to the bottom of the chat window
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [messages.length, progress?.text]);

  const handleScroll = (evt: React.UIEvent<HTMLDivElement>) => {
    if (bodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = bodyRef.current;
      if (scrollTop < 20) {
        onLoadMore();
      }
    }
  };

  return (
    <ChatWrapper className="chat-window">
      {/*<Narration narration={lastAssistantMessage?.text || null} />*/}

      <ChatHeader className="chat-window__header">
        <Grid container flexDirection="row" spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              variant="circular"
              sx={{ width: 50, height: 50 }}
              src="https://pbs.twimg.com/ext_tw_video_thumb/1698291426340098048/pu/img/14c-fPj9Kz08zFjr.jpg"
              alt="Avatar"
            />
          </Grid>

          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              Chatting with Volo
            </Typography>

            <Typography variant="subtitle2">
              {!isWaiting && !progress && "Connnected and ready to chat"}
              {!isWaiting && progress && progress.text}
              {isWaiting && "Working on processing your request"}
            </Typography>
          </Grid>
        </Grid>
      </ChatHeader>

      <ChatBody
        ref={bodyRef}
        className="chat-window__body"
        onWheel={handleScroll}
      >
        {messages.length === 0 && (
          <>
            <MessageWrapper key="message-welcome">
              <ChatMessage
                local={false}
                message={{
                  id: "welcome",
                  sender: "assistant",
                  text: "Hey there! Ready to get started building your fantasy world? Let's get started!",
                  createdAt: new Date().toISOString(),
                  attachments: [],
                }}
              />
            </MessageWrapper>

            <MessageWrapper key="message-welcome2">
              <ChatMessage
                local={false}
                message={{
                  id: "welcome",
                  sender: "assistant",
                  text: "Send me a message when you're ready to go.",
                  createdAt: new Date().toISOString(),
                  attachments: [],
                }}
              />
            </MessageWrapper>
          </>
        )}

        {messages.map((message, idx) => (
          <MessageWrapper key={`message-${idx}`}>
            <ChatMessage local={message.sender === "user"} message={message} />
          </MessageWrapper>
        ))}

        {progress && (
          <MessageWrapper key="message-status">
            <ChatMessage local={false} message={progress} loading />
          </MessageWrapper>
        )}
      </ChatBody>

      <ChatFooter className="chat-window__footer">
        {isWaiting && (
          <LinearProgress variant="indeterminate" style={{ width: "100%" }} />
        )}
        <ChatInput
          onSubmit={(message, files) => {
            sendMessage(message, files);
          }}
        />
      </ChatFooter>
    </ChatWrapper>
  );
};
