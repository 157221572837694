import { Box, Button, Chip, Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import { ZoomIn } from "react-feather";
import styled from "styled-components";
import { Config } from "../../../config";
import { MessageAttachment } from "../../../types";

const MagnifyContainer = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.visible {
    opacity: 1;
  }
`;

export const Attachment = ({ name, id }: MessageAttachment) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Chip
      size="small"
      label={name}
      clickable
      onClick={() => {
        window.open(`${Config.ApiBaseUrl}/assets/${id}`, "_blank");
      }}
    />
  );

  /*const imageMemo = useMemo(() => {
    return (
      <img
        src={`${Config.ApiBaseUrl}/assets/${fileId}`}
        alt="attachment"
        onLoad={() => setIsImageLoaded(true)}
        style={{
          width: "100%",
          objectFit: "cover",
          aspectRatio: 3,
        }}
      />
    );
  }, [fileId]);

  return (
    <Box marginBottom="1em">
      <Button
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {!isImageLoaded && (
          <Skeleton variant="rectangular" height="300px" width="400px" />
        )}
        {isImageLoaded && imageMemo}

        <MagnifyContainer className={isHovering ? "visible" : ""}>
          <ZoomIn color="#fff" size="2.6rem" />
        </MagnifyContainer>
      </Button>
    </Box>
  );*/
};
