import { Box, Grid, Paper, Typography } from "@mui/material";
import { useWorld } from "../../../context/app/hooks/useWorld";
import {
  Characters,
  Creatures,
  Locations,
  Races,
  Routes,
  WorldInformation,
} from "../../organisms/world-overview";
import { useEffect, useRef, useState } from "react";
import { WorldData } from "../../../types";

export const WorldOverview = () => {
  const world = useWorld();
  const worldRef = useRef<WorldData | null>(world);
  const preUpdateRef = useRef<WorldData | null>(world);
  const [worldShouldShow, setWorldShouldShow] = useState(!!world);
  const [sectionsToHighlight, setSectionsToHighlight] = useState<
    (keyof WorldData)[]
  >([]);
  const bodyParentRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!worldRef.current && world) {
      setTimeout(() => {
        setWorldShouldShow(true);
        worldRef.current = world;

        if (!preUpdateRef.current) preUpdateRef.current = world;
      }, 1300);
    }
  }, [world]);

  useEffect(() => {
    if (!world || !preUpdateRef.current) return;

    const properties = Object.keys(world);

    const sectionsToHighlight: (keyof WorldData)[] = [];

    properties.forEach((property) => {
      if (
        preUpdateRef.current &&
        JSON.stringify((preUpdateRef as any).current[property]) !==
          JSON.stringify((world as any)[property])
      ) {
        sectionsToHighlight.push(property as keyof WorldData);
      }
    });

    setSectionsToHighlight(sectionsToHighlight);
  }, [world]);

  /*useEffect(() => {
    if (sectionsToHighlight.length === 0) return;

    const timeoutIds: NodeJS.Timeout[] = [];

    sectionsToHighlight.forEach((section, idx) => {
      timeoutIds.push(
        setTimeout(() => {
          // Scroll the parent element to the top of the section
          if (!bodyParentRef.current) return;

          const sectionElement = bodyParentRef.current.querySelector(
            `#${section}`
          );

          if (!sectionElement) return;

          const rect = sectionElement.getBoundingClientRect();

          bodyParentRef.current.scrollTo({
            top: rect.top + bodyParentRef.current.scrollTop - 100,
            behavior: "smooth",
          });
        }, 3000 * idx)
      );
    });

    timeoutIds.push(
      setTimeout(() => {
        setSectionsToHighlight([]);
      }, 3000 * sectionsToHighlight.length)
    );

    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [sectionsToHighlight]);*/

  return (
    <Paper
      elevation={8}
      style={{
        transform: worldShouldShow ? "translateY(0)" : "translateY(100vh)",
        transition: "transform 1.1s ease-in-out",
      }}
      ref={(r) => {
        if (!r) return;

        if (!bodyParentRef.current) bodyParentRef.current = r.parentElement;
      }}
    >
      <Grid
        container
        spacing={4}
        justifyContent={!world ? "center" : undefined}
        alignContent={!world ? "center" : undefined}
        flexDirection="column"
        flexWrap="nowrap"
        id="world-overview"
        style={{ height: "100%" }}
      >
        {!world && (
          <Grid item alignSelf="center" justifySelf="center">
            <Typography variant="h4">No world selected</Typography>
          </Grid>
        )}

        {world && (
          <>
            {world.description && (
              <Grid id="description" item xs={12}>
                <Box padding="2em">
                  <WorldInformation />
                </Box>
              </Grid>
            )}

            {world.races && (
              <Grid id="races" item xs={12}>
                <Box padding="2em">
                  <Races />
                </Box>
              </Grid>
            )}

            {world.characters && (
              <Grid id="characters" item xs={12}>
                <Box padding="2em">
                  <Characters />
                </Box>
              </Grid>
            )}

            {world.regions && (
              <Grid id="locations" item xs={12}>
                <Box padding="2em">
                  <Locations />
                </Box>
              </Grid>
            )}

            {world.routes && (
              <Grid id="routes" item xs={12}>
                <Box padding="2em">
                  <Routes />
                </Box>
              </Grid>
            )}

            {world.creatures && (
              <Grid id="creatures" item xs={12}>
                <Box padding="2em">
                  <Creatures />
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
};
