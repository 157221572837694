import "./App.css";
import { AppProvider, useIdentity } from "./context";
import { Login, WorldBuilder } from "./components/templates";

function App() {
  return (
    <AppProvider>
      <Root />
    </AppProvider>
  );
}

function Root() {
  const { isAuthenticated } = useIdentity();

  if (isAuthenticated) {
    return <WorldBuilder />;
  }

  return <Login />;
}

export default App;
