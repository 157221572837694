import { useContext } from "react";
import { Message } from "../../../types";
import { ChatContext } from "../ChatContext";

export const useChatMessages = (): [
  Message[],
  (message: string, attachmentIds: string[]) => void,
  boolean,
  Message | null
] => {
  const { messages, sendMessage, statusMessage, isAwaitingResponse } =
    useContext(ChatContext);

  return [
    messages,
    (message: string, attachmentIds: string[]) =>
      sendMessage(message, attachmentIds),
    isAwaitingResponse,
    statusMessage,
  ];
};
