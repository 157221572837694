import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
} from "@mui/material";
import { Location } from "../../../types";
import { Config } from "../../../config";
import { TypedText } from "../../atoms";
import { VisualFeature } from "./VisualFeature";
import { Frame } from "../../../styles";

interface LocationInformationProps {
  location: Partial<Location>;
  alignment: "left" | "right";
}

export const LocationInformation = ({
  location,
  alignment,
}: LocationInformationProps) => {
  const textAlign = alignment === "left" ? "right" : "left";

  const typeString = location.type
    ? ` ${
        location.type.toString().substring(0, 1).toUpperCase() +
        location.type.toString().substring(1)
      }`
    : "Unknown Type";

  return (
    <VisualFeature imageAssetId={location.imageAssetId} alignment={alignment}>
      <Frame style={{ height: "100%" }}>
        <TypedText fontSize="2.4vh" fontWeight="900" textAlign={alignment}>
          {location.name}
        </TypedText>
        <TypedText fontSize="1.4vh" textAlign={alignment}>
          {location.primaryRace || "Neutral"} {typeString}
        </TypedText>

        <Divider />

        <TypedText
          fontSize="1.4vh"
          textAlign={alignment}
        >{`*Description*\n${location.description}`}</TypedText>
      </Frame>
    </VisualFeature>
  );
};
