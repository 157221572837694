import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { Attachment } from "./Attachment";
import { Message } from "../../../types";
import { useEffect, useMemo, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  opacity: 1;
  transition: all 1.2s ease;

  &.new {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  margin-right: 0.45em;
  margin-left: 0.45em;

  @media (max-width: 1300px) {
    display: none;
  }
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;

  &.local {
    flex-grow: 1;
    justify-items: flex-end;
  }
`;

const MessageBubble = styled.div`
  display: inline-flex;
  flex-direction: column;
  background-color: #dbdbdb;
  border-bottom-left-radius: 1.1rem;
  border-bottom-right-radius: 1.1rem;
  border-top-right-radius: 1.1rem;
  overflow: hidden;
  padding: 1em;

  &.local {
    background-color: #b3d9f3;
    margin-left: auto;
    justify-content: flex-end;
    border-top-right-radius: 0;
    border-bottom-left-radius: 1.1rem;
    border-bottom-right-radius: 1.1rem;
    border-top-left-radius: 1.1rem;
  }
`;

const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.25em;

  &.local {
    justify-content: flex-end;
  }
`;

const MessageText = styled.span`
  margin: 0;
`;

interface ChatMessageProps {
  local?: boolean;
  message: Message;
  loading?: boolean;
}

export const ChatMessage = ({ local, message, loading }: ChatMessageProps) => {
  const [isNew, setIsNew] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsNew(false);
    }, 100);

    return () => {
      setIsNew(true);
    };
  }, []);

  const Photo = () => {
    return (
      <Avatar
        variant="circular"
        style={{
          width: "100%",
          aspectRatio: 1,
          maxWidth: "4em",
          margin: "auto auto",
        }}
        src={
          local
            ? ""
            : "https://pbs.twimg.com/ext_tw_video_thumb/1698291426340098048/pu/img/14c-fPj9Kz08zFjr.jpg"
        }
      />
    );
  };

  const markupText = useMemo(() => {
    if (!message.text) return "";

    const content = message.text.replace(/\n/g, "<br>");

    // Use regex to replace ** with <strong> and * with <em>
    const strongRegex = /\*\*(.*?)\*\*/g;
    const emRegex = /\*(.*?)\*/g;

    const strongMatches = content.match(strongRegex);
    const emMatches = content.match(emRegex);

    let result = content;

    if (strongMatches) {
      strongMatches.forEach((match) => {
        result = result.replace(
          match,
          `<strong>${match.replace(/\*\*/g, "")}</strong>`
        );
      });
    }

    if (emMatches) {
      emMatches.forEach((match) => {
        result = result.replace(match, `<i>${match.replace(/\*/g, "")}</i>`);
      });
    }

    // Use regex to replace ### with <h3> only on the same line
    const h3Regex = /###(.*?)<br>/g;
    const h3Matches = content.match(h3Regex);

    if (h3Matches) {
      h3Matches.forEach((match) => {
        result = result.replace(
          match,
          `<span style="margin: 0; font-size: 21px; font-weight: 800;">${match.replace(
            /#/g,
            ""
          )}</span>`
        );
      });
    }

    return result;
  }, [message.text]);

  return (
    <Wrapper className={isNew ? "new" : ""}>
      {!local && (
        <AvatarContainer
          style={{ alignSelf: "flex-start", alignItems: "flex-start" }}
        >
          <Photo />
        </AvatarContainer>
      )}

      <MessageContent
        className={`chat-message__content${local ? " local" : ""}`}
      >
        <MessageBubble
          className={`chat-message__bubble${local ? " local" : ""}`}
        >
          {message.text && (
            <MessageText
              dangerouslySetInnerHTML={{
                __html: markupText,
              }}
            />
          )}
        </MessageBubble>

        {message.attachments
          ? message.attachments
              .filter((x) => x.name.indexOf("world_information.txt") === -1)
              .map((attachment) => (
                <AttachmentWrapper className={local ? "local" : ""}>
                  <Attachment {...attachment} />
                </AttachmentWrapper>
              ))
          : null}
      </MessageContent>

      {local && (
        <AvatarContainer
          style={{ alignSelf: "flex-start", justifyItems: "flex-end" }}
        >
          <Photo />
        </AvatarContainer>
      )}
    </Wrapper>
  );
};
