import { Chip, Grid, Typography } from "@mui/material";
import { Route, RouteDangerLevel, RouteType } from "../../../types";
import { TypedText } from "../../atoms";
import { Frame } from "../../../styles";

interface RouteInformationProps {
  route: Partial<Route>;
}

export const RouteInformation = ({ route }: RouteInformationProps) => {
  return (
    <Grid
      container
      flexDirection="column"
      flexWrap="nowrap"
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Frame>
          <TypedText fontSize="2.8vh" fontWeight="bold">
            {route.name}
          </TypedText>
          <TypedText fontSize="1.4vh" fontStyle="italic">
            {route.dangerLevel || ""} {route.type || "Unknown route"}
          </TypedText>

          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize="1.2vh"
            style={{ marginTop: "1em" }}
          >
            Description
          </Typography>
          <TypedText fontSize="1.4vh">{route.description}</TypedText>

          {route.connectedLocationNames && (
            <>
              <Typography variant="body1" fontWeight="bold" fontSize="1.2vh">
                Connected Locations
              </Typography>

              <Grid
                container
                flexDirection="row"
                columnSpacing={2}
                rowSpacing={4}
                flexWrap="wrap"
              >
                {route.connectedLocationNames.map((loc) => (
                  <Grid item>
                    <Chip label={loc} color="primary" />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {route.hazard && (
            <>
              <Typography variant="body1" fontWeight="bold" fontSize="1.2vh">
                Hazards
              </Typography>
              <TypedText fontSize="1.4vh">{route.hazard}</TypedText>
            </>
          )}

          {route.blockage && (
            <>
              <Typography variant="body1" fontWeight="bold" fontSize="1.2vh">
                Blockage
              </Typography>
              <TypedText fontSize="1.4vh">{route.blockage}</TypedText>
            </>
          )}
        </Frame>
      </Grid>
    </Grid>
  );
};
