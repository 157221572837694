import { Chip, IconButton, Typography } from "@mui/material";
import { XCircle } from "react-feather";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface AttachmentPreviewProps {
  file: File;
  percentage?: number;
  onRemove: () => void;
}

export const AttachmentPreview = ({
  file,
  percentage,
  onRemove,
}: AttachmentPreviewProps) => {
  return (
    <Chip
      size="small"
      label={
        <Wrapper>
          <Typography variant="body2">
            {file.name}{" "}
            {typeof percentage !== "undefined" ? `(${percentage}%)` : ""}
          </Typography>
          <IconButton onClick={onRemove}>
            <XCircle size="18px" />
          </IconButton>
        </Wrapper>
      }
    />
  );
};
