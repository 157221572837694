import { Chip, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { Download, Mic, MicOff, Upload } from "react-feather";
import styled from "styled-components";
import { AttachmentPreview } from "./AttachmentPreview";
import AssetApi from "../../../api/AssetApi";
import { ChatContext, useSession } from "../../../context";
import html2canvas from "html2canvas";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  padding: 1em;
  padding-left: 1em;
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1em;
`;

interface ChatInputProps {
  onSubmit: (message: string, attachmentIds: string[]) => void;
}

interface AttachedFile {
  file: File;
  assetId?: string;
  percentage?: number;
}

export const ChatInput = ({ onSubmit }: ChatInputProps) => {
  const [session] = useSession();
  const { isAwaitingResponse } = useContext(ChatContext);
  const [message, setValue] = useState("");
  const [files, setFiles] = useState<AttachedFile[]>([]);
  const [speechToTextEnabled, setSpeechToTextEnabled] = useState(false);
  const speechRecognitionRef = useRef<SpeechRecognition | null>(null);
  const recognizerStartedRef = useRef(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAttachFile = (file: File) => {
    if (!session) return;

    setFiles((prev) => [
      ...prev,
      {
        file,
        percentage: 0,
      },
    ]);

    AssetApi.create(session, file, (percentage) => {
      setFiles((prev) =>
        prev.map((f) => {
          if (f.file.name === file.name) {
            return {
              ...f,
              percentage,
            };
          }

          return f;
        })
      );
    }).then((asset) => {
      setFiles((prev) =>
        prev.map((f) => {
          if (f.file.name === file.name) {
            return {
              ...f,
              assetId: asset.id,
              percentage: undefined,
            };
          }

          return f;
        })
      );
    });
  };

  const handleFileInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      for (let i = 0; i < evt.target.files.length; i++) {
        handleAttachFile(evt.target.files[i]);
      }
    }

    evt.target.value = "";
  };

  const handleRemoveAttachment = (file: File) => {
    setFiles((prev) => prev.filter((f) => f.file.name !== file.name));
  };

  const handleStartRecognition = () => {
    if (speechRecognitionRef.current && !recognizerStartedRef.current) {
      speechRecognitionRef.current.start();
      recognizerStartedRef.current = true;
    } else if (!speechRecognitionRef.current) {
      // Create a new instance of SpeechRecognition using the appropriate window object
      speechRecognitionRef.current = new (
        window as any
      ).webkitSpeechRecognition() as SpeechRecognition;

      speechRecognitionRef.current.start();
      recognizerStartedRef.current = true;
    }
  };

  const toggleDictation = () => {
    if (speechToTextEnabled) {
      speechRecognitionRef.current?.stop();
      recognizerStartedRef.current = false;
      speechRecognitionRef.current = null;
      setSpeechToTextEnabled(false);
    } else {
      handleStartRecognition();

      if (!speechRecognitionRef.current) return;

      // Set continuous to true to allow for real-time transcription
      speechRecognitionRef.current.continuous = true;

      // Set the value as the user speaks
      speechRecognitionRef.current.onresult = (event) => {
        // get the latest transcript so we can live update the value
        const transcript = event.results[0][0].transcript;
        setValue(transcript);

        // If they're done speaking, stop the recording and submit the message
        if (event.results[0].isFinal) {
          speechRecognitionRef.current?.stop();

          onSubmit(
            transcript,
            files.map((x) => x.assetId!)
          );
          setValue("");
          setFiles([]);

          handleStartRecognition();
        }
      };

      setSpeechToTextEnabled(true);
    }
  };

  return (
    <>
      <AttachmentList>
        {files.map(({ file, percentage }) => (
          <AttachmentPreview
            file={file}
            percentage={percentage}
            onRemove={() => handleRemoveAttachment(file)}
          />
        ))}

        {files.length === 0 && (
          <Chip label="No attachments" style={{ opacity: 0 }} />
        )}
      </AttachmentList>
      <InputContainer>
        <Tooltip
          title={speechToTextEnabled ? "Disable dictation" : "Enable dictation"}
        >
          <IconButton onClick={toggleDictation}>
            {speechToTextEnabled && <Mic />}
            {!speechToTextEnabled && <MicOff color="maroon" />}
          </IconButton>
        </Tooltip>

        <TextField
          fullWidth
          disabled={
            (files.length > 0 && files.some((x) => !x.assetId)) ||
            isAwaitingResponse
          }
          autoComplete="off"
          placeholder="Type a message..."
          value={message}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyUp={(evt) => {
            if (evt.key === "Enter") {
              onSubmit(
                message,
                files.map((x) => x.assetId!)
              );
              setValue("");
              setFiles([]);
            }
          }}
          style={{ marginLeft: "1em", marginRight: "1em" }}
        />

        <Tooltip title="Upload an attachment for more context">
          <IconButton
            disabled={!session}
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <Upload />
          </IconButton>
        </Tooltip>

        <Tooltip title="Save as PDF">
          <IconButton
            disabled={!session}
            onClick={() => {
              html2canvas(document.querySelector("#world-overview")!, {
                allowTaint: true,
                useCORS: true,
              }).then((canvas) => {
                const link = document.createElement("a");
                link.download = "world-overview.png";
                link.href = canvas.toDataURL("image/png");
                link.click();
              });
            }}
          >
            <Download />
          </IconButton>
        </Tooltip>
      </InputContainer>

      <input type="file" ref={fileInputRef} onChange={handleFileInput} hidden />
    </>
  );
};
